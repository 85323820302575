.mv {
  &.-top {
    width: 100%;
    height: 1042px;
    background: url(../images/top/mv.jpg)no-repeat bottom;
    background-size: cover;

    @include mq(sp) {
      height: pxtovw(553);
      background: url(../images/top/sp/mv.jpg)no-repeat center;
      background-size: cover;
    }
  }

  &.-grand-menu {
    background: url(../images/page/mv-menu.jpg)no-repeat center top;

    @include mq(sp) {
      position: relative;
      background: url(../images/page/sp/mv-menu.jpg)no-repeat center top;
      background-size: contain;
      height: pxtovw(200);
    }
  }
}

.mv-title {
  padding: 237px 0 315px;
  font-size: 70px;
  font-weight: 700;
  color: #fff;
  text-align: center;

  @include mq(sp) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    padding: 0;
    width: 100%;
    font-size: 30px;
  }
}