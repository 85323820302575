.section-title {
  margin-bottom: 80px;
  padding: 50px 0;
  text-align: center;

  .ja {
    display: block;
    font-size: 50px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.1em;
  }

  .en {
    display: block;
    margin-top: 18px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

  &.no01 {
    background: url(../images/common/bg-title01.png)no-repeat center;

    .en {
      color: #E07000;
    }
  }



  &.no02 {
    background: url(../images/common/bg-title02.png)no-repeat center;

    .en {
      color: #E03C00;
    }
  }

  @include mq(sp) {
    margin-bottom: 30px;

    .ja {
      font-size: 30px;
    }

    .en {
      font-size: 14px;
    }
  }
}