footer {
  padding: 40px 0;
  background: #331A14;
  text-align: center;

  small {
    display: block;
    margin-top: 30px;
    font-size: 12px;
    color: #fff;
    line-height: (29px / 12px);
  }
}