.header {
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  transform: translateY(-100%);
  opacity: 0;

  &.active {
    transform: translateY(0);
    opacity: 1;
    background: rgba($color: #fff, $alpha: 0.7);
    transition: 0.5s;
  }
}

.header-logo {
  padding: 20px 30px 0;

  img {
    max-width: 90px;
    height: auto;
  }

  @include mq(tb) {
    padding: 0;

    img {
      max-width: 64px;
      height: auto;
    }
  }
}

.header-inner {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  @include mq(tb) {
    justify-content: flex-start;
    padding: 14px 10px;

  }
}

.header-menu-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
}

.header-menu-item {
  a {
    display: block;
    font-size: 20px;
    font-weight: 700;
    color: $text_color;
  }

  +.header-menu-item {
    margin-left: 50px;

    @media print,
    screen and (max-width: 1120px) {
      margin-left: 20px;
    }

    @include mq(tb) {
      margin-left: 0;
    }
  }

  &.-contact {
    a {
      display: block;
      padding: 30px 70px 30px 50px;
      background: $text_color;
      color: #fff;

      @media print,
      screen and (max-width: 1120px) {
        padding: 30px 35px 30px 30px;
      }

      @include mq(sp) {
        text-align: center;
        padding: 20px 35px 20px 30px;
      }
    }
  }

  &.header-menu-item-logo {
    display: none;

    @include mq(tb) {
      display: block;

      img {
        width: 155px;
        height: auto;
      }
    }
  }
}

#header-menu {
  @include mq(tb) {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 20;
    color: #000;
    width: 100%;
    height: 100vh;
    border: 0;
    background: #F9F3E9;
    padding: 0;

    ul {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 auto;
      padding: 0 20px;
      width: 100%;


      li {
        position: relative;
        margin-bottom: 40px;
        font-size: 15px;
        list-style-type: none;
        padding: 0;
        width: 100%;

        &:last-child {
          margin-top: 60px;
          padding-bottom: 0;
        }

        &.header-menu-item-logo {
          margin-bottom: 45px;
        }
      }
    }
  }
}

.navToggle {
  display: none;

  p {
    font-size: 10px;
  }

  @include mq(tb) {
    /* このクラスを、jQueryで付与・削除する */
    display: block;
    position: absolute;
    right: 10px;
    top: 5px;
    width: 30px;
    height: 18px;
    cursor: pointer;
    z-index: 2000;
    text-align: center;

    span {
      display: block;
      position: absolute;
      /* .navToggleに対して */
      width: 30px;
      height: 2px;
      background: #331A14;
      transition: 0.35s ease-in-out;

    }

    span:nth-child(1) {
      top: 16px;
    }

    span:nth-child(2) {
      top: 25px;
    }

    span:nth-child(3) {
      top: 33px;
    }

    p {
      position: absolute;
      top: 35px;
    }

    &.active {
      background: none;
    }

    /* 最初のspanをマイナス45度に */
    &.active span:nth-child(1) {
      border-color: #fff;
      top: 27px;
      transform: rotate(-30deg);
    }

    /* 2番目と3番目のspanを45度に */
    &.active span:nth-child(2),
    &.active span:nth-child(3) {
      border-color: #fff;
      top: 27px;
      transform: rotate(30deg);
    }
  }
}