* {
  box-sizing: border-box;
}

body {
  font-size: 18px;
  font-family: 'Noto Serif JP', serif;
  font-weight: 400;
  line-height: 1;
  color: $text_color;

}

ul {
  &.inside {
    padding-left: 18px;
    text-indent: -18px;
  }
}

li {
  list-style-type: none;
}

a {
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: $key_color;

  &:hover {
    opacity: 0.7;
    text-decoration: none !important;
  }

  &:visited {
    text-decoration: none;
  }
}

input {
  outline: none;
}

img {
  vertical-align: bottom;

  @include mq(sp) {
    max-width: 100%;
    height: auto;
  }
}