.about-item {
  height: 685px;
  position: relative;

  @include mq(tb) {
    padding: 0 20px;
    height: 100%;

    .container {
      padding: 0;
    }
  }

  &.no01 {
    position: relative;
    background: url(../images/top/bg-about01.png)no-repeat right bottom;
    background-size: 34.5% auto;
  }

  &.no02 {
    position: relative;
    background: url(../images/top/bg-about02.png)no-repeat left center;
    background-size: 34.5% auto;

    @include mq(tb) {
      margin-top: 30px;
    }
  }
}

.about-photo {
  height: 550px;
  margin-bottom: 85px;
  padding-bottom: 40px;

  @include mq(tb) {
    height: pxtovw(200);
  }

  .no01 & {
    background: url(../images/top/about01.jpg)no-repeat left center;
    background-size: cover;
    width: 65.5%;
    position: absolute;
    left: 0;
  }

  .no02 & {
    background: url(../images/top/about02.jpg)no-repeat right center;
    background-size: cover;
    width: 65.5%;
    position: absolute;
    right: 0;
  }

  @include mq(tb) {
    margin-bottom: 0;

    .no01 &,
    .no02 & {
      display: block;
      position: static;
      right: unset;
      left: unset;
      width: 100%;
    }
  }
}

.about-text {
  position: relative;
  width: 58%;
  max-width: 600px;
  min-width: 375px;
  height: auto;
  border-top: 5px solid #E03C00;
  background: #fff;
  padding: 45px 50px;
  line-height: (33px / 18px);
  z-index: 10;

  .no01 & {
    top: 160px;
    margin-left: 50%;
  }

  .no02 & {
    top: 140px;
  }

  @include mq(tb) {
    width: 100%;
    max-width: unset;
    min-width: unset;
    padding: 15px;

    .no01 & {
      top: 0;
      margin-left: 0;
    }

    .no02 & {
      top: 0;
    }
  }

  p {
    +p {
      margin-top: 20px;
    }
  }
}

.card__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &.-takeout {
    margin-top: -90px;
  }

  &.-grand-menu {
    margin-top: -30px;
  }

  @include mq(tb) {
    flex-direction: column;

    &.-takeout {
      margin-top: 30px;
    }
  }
}

.card__item {
  color: #fff;


  &.-takeout {
    margin-top: 90px;
    width: 560px;
    border: 6px solid #F4E8D4;

    @media print,
    screen and (max-width: 1120px) {
      width: 480px;
    }

    @include mq(tb) {
      margin-top: 30px;
      width: 100%;
    }
  }

  &.-grand-menu {
    margin-top: 30px;
    width: 380px;
    border: 6px solid #F4E8D4;

    @media print,
    screen and (max-width: 1160px) {
      width: 320px;
    }

    @include mq(tb) {
      width: 100%;
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}


.bg-color03 {
  background: linear-gradient(to right, #503c91 0%, #331a14 100%);
}

.bg-takeout {
  background: url(../images/top/bg-takeout.png)repeat-y top center;
}

@include mq(sp) {
  .bg-takeout {
    background: url(../images/top/sp/bg-takeout.png)repeat-y top center;
    background-size: contain;
  }
}

@media screen and (min-width: 1920px) {
  .bg-takeout {
    background-size: contain;
  }
}

@media screen and (min-width: 769px) {
  .bg-grand-menu-head {
    background: url(../images/top/bg-grand-menu01.png)no-repeat left top;
  }

  .bg-grand-menu-bottom {
    background: url(../images/top/bg-grand-menu02.png)no-repeat right bottom;
  }
}

@include mq(tb) {
  .bg-grand-menu {
    background: url(../images/top/sp/bg-grand-menu.png)repeat-y;
    background-size: contain;
  }
}

.menu-card__content {
  padding: 30px 40px 40px 40px;
  text-align: center;

  @media screen and (min-width: 993px) {
    &.bg-color01 {
      background: linear-gradient(to right, #e07000 0%, #331a14 100%);

    }

    &.bg-color02 {
      background: linear-gradient(to right, #e03c00 0%, #331a14 100%);
    }
  }

  @include mq(tb) {
    .card__item:nth-child(odd) & {
      background: linear-gradient(to right, #e07000 0%, #331a14 100%);
    }

    .card__item:nth-child(even) & {
      background: linear-gradient(to right, #e03c00 0%, #331a14 100%);
    }
  }

  @media print,
  screen and (max-width: 1120px) {
    padding: 15px 20px 20px 20px;
  }
}

.menu-card__name {
  border-bottom: 1px solid #fff;
  padding-bottom: 15px;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.1em;
}


.menu-card__price {
  padding-top: 15px;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.1em;

  .small {
    font-size: 20px;
  }
}

.p-info-btn-wrapper {
  background-color: #fff;
  margin: 100px auto 0;
  position: relative;
  width: 450px;
  max-width: 100%;
  text-align: center;
  background-color: #fff;

  @include mq(tb) {
    width: 345px;
  }

  &::after {
    position: absolute;
    display: inline-block;
    content: "";
    top: 5px;
    left: 5px;
    z-index: 1;
    background: #E03C00;
    width: 100%;
    height: 80px;

    @include mq(tb) {
      left: unset;
      right: -5px;
      width: 345px;
      height: 56px;
    }
  }
}

.c-info-btn {
  width: 450px;
  max-width: 100%;
  display: inline-block;
  padding: 25px 13px;
  background: #fff;
  border: 2px solid #331A14;
  text-align: center;
  font-weight: bold;
  font-size: 26px;
  color: $text_color;
  position: relative;
  z-index: 30;

  &:hover {
    opacity: 1;
    background: #331A14;
    color: #fff;
  }

  @include mq(tb) {
    padding: 17px 13px;
    font-size: 18px;
  }

  @include mq(sp) {
    width: 345px;
  }
}

.contact {
  &.no01 {
    margin-top: 95px;
    text-align: center;

    .contact__lead {
      font-size: 28px;
      font-weight: 700;
      letter-spacing: 0.1em;

      @include mq(tb) {
        font-size: 24px;
        line-height: (42px / 24px);
      }
    }

    .contact__number {
      display: inline-block;
      margin-top: 30px;
      padding-left: 60px;
      background: url(../images/common/tel.svg)no-repeat left center;
      font-size: 50px;
      font-weight: 700;
      letter-spacing: 0.1em;
      color: $text_color;

      @include mq(sp) {
        padding-left: 40px;
        background-size: 30px 27px;
        font-size: 37px;
      }
    }
  }

  &.no02 {
    text-align: center;

    .contact__lead {
      font-size: 28px;
      font-weight: 700;
      letter-spacing: 0.1em;
      line-height: (42px / 28px);

      @include mq(tb) {
        font-size: 24px;
      }
    }

    .contact__number {
      display: inline-block;
      margin-top: 55px;
      padding: 25px 70px 25px 130px;
      background: url(../images/common/tel.svg)no-repeat left 70px center;
      background-color: #fff;
      font-size: 50px;
      font-weight: 700;
      letter-spacing: 0.1em;
      color: $text_color;

      @include mq(sp) {
        padding: 25px 0 25px 60px;
        background: url(../images/common/tel.svg)no-repeat left 10px center;
        background-size: 30px 27px;
        background-color: #fff;
        font-size: 37px;
      }
    }
  }
}

.contact-box {
  width: 52%;
  min-width: 993px;
  margin: 80px auto 0;
  padding: 60px 50px 50px;
  background: #F9F3E9;

  @include mq(tb) {
    width: 100%;
    min-width: auto;
    padding: 20px;
  }
}

.contact__title {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-align: center;

  @include mq(tb) {
    font-size: 22px;
    line-height: (33px /22px);
  }
}

.contact__text {
  margin-top: 20px;
  line-height: (33px / 18px);
}

.section.bg-access {
  background: url(../images/top/bg-access01.png)no-repeat left top 164px, url(../images/top/bg-access02.png)no-repeat right bottom;
  padding: 150px 0 103px;
}

@include mq(sp) {
  .section.bg-access {
    background: url(../images/top/sp/bg-access01.png)no-repeat left top 280px, url(../images/top/sp/bg-access02.png)no-repeat right bottom;
    padding: 150px 0 15px;
  }
}

.access__list {
  margin-top: 75px;
  text-align: center;
}

@include mq(sp) {
  .access__list {
    margin-top: 40px;
  }
}

.access__item {
  font-size: 22px;
  text-align: center;
  line-height: 2;

  +.access__item {
    margin-top: 20px;
  }

  @include mq(sp) {
    font-size: 18px;
    line-height: 1.5;
  }
}

.gmap {
  height: 600px;
  overflow: hidden;

  .gmap__inner {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
  }

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  @include mq(tb) {
    height: auto;
  }
}

.bg-contact {
  background: url(../images/top/bg-contact01.png)no-repeat left top, url(../images/top/bg-contact02.png)no-repeat right bottom;
}

@include mq(sp) {
  .bg-contact {
    background: url(../images/top/sp/bg-contact01.png)no-repeat left top, url(../images/top/sp/bg-contact02.png)no-repeat right bottom;
  }
}

.link__list {
  display: flex;

  @include mq(tb) {
    flex-wrap: wrap;
  }
}

.link__item {
  position: relative;
  width: 25%;

  a {
    display: block;
    width: 100%;
    height: 100%;

    &:hover {
      opacity: 1;
    }

    &::after {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      transition: 1.0s;
      background: transparent linear-gradient(135deg, var(--unnamed-color-e07000) 0%, var(--unnamed-color-331a14) 100%) 0% 0% no-repeat padding-box;
      background: transparent linear-gradient(135deg, #E07000 0%, #331A14 100%) 0% 0% no-repeat padding-box;
      opacity: 0.4;
    }

    &:hover {
      &::after {
        background: transparent linear-gradient(135deg, var(--unnamed-color-e07000) 0%, var(--unnamed-color-331a14) 100%) 0% 0% no-repeat padding-box;
        background: transparent linear-gradient(135deg, #E03C00 0%, #331A14 100%) 0% 0% no-repeat padding-box;
        opacity: 0.7;
      }
    }
  }

  .link__image {
    width: 100%;
    height: auto;
  }

  span {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    z-index: 100;
    text-align: center;

    img {
      display: inline-block;
    }

    &:hover {
      opacity: 1;
    }

    @media print,
    screen and (max-width: 1120px) {
      font-size: 30px;
    }

    @include mq(tb) {
      font-size: 18px;

      img {
        margin-top: 15px;
      }
    }
  }

  @include mq(tb) {
    width: 50%;
  }
}