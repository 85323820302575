@charset "UTF-8";
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

* {
  box-sizing: border-box;
}

body {
  font-size: 18px;
  font-family: 'Noto Serif JP', serif;
  font-weight: 400;
  line-height: 1;
  color: #331A14;
}

ul.inside {
  padding-left: 18px;
  text-indent: -18px;
}

li {
  list-style-type: none;
}

a {
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #00accf;
}

a:hover {
  opacity: 0.7;
  text-decoration: none !important;
}

a:visited {
  text-decoration: none;
}

input {
  outline: none;
}

img {
  vertical-align: bottom;
}

@media print, screen and (max-width: 750px) {
  img {
    max-width: 100%;
    height: auto;
  }
}

.container {
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 20px;
}

@media print, screen and (max-width: 750px) {
  .container {
    padding: 0 20px;
  }
}

.section {
  padding: 150px 0;
}

@media print, screen and (max-width: 750px) {
  .section {
    padding: 40px 0;
  }
}

.header {
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  transform: translateY(-100%);
  opacity: 0;
}

.header.active {
  transform: translateY(0);
  opacity: 1;
  background: rgba(255, 255, 255, 0.7);
  transition: 0.5s;
}

.header-logo {
  padding: 20px 30px 0;
}

.header-logo img {
  max-width: 90px;
  height: auto;
}

@media print, screen and (max-width: 992px) {
  .header-logo {
    padding: 0;
  }
  .header-logo img {
    max-width: 64px;
    height: auto;
  }
}

.header-inner {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

@media print, screen and (max-width: 992px) {
  .header-inner {
    justify-content: flex-start;
    padding: 14px 10px;
  }
}

.header-menu-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
}

.header-menu-item a {
  display: block;
  font-size: 20px;
  font-weight: 700;
  color: #331A14;
}

.header-menu-item + .header-menu-item {
  margin-left: 50px;
}

@media print, screen and (max-width: 1120px) {
  .header-menu-item + .header-menu-item {
    margin-left: 20px;
  }
}

@media print, screen and (max-width: 992px) {
  .header-menu-item + .header-menu-item {
    margin-left: 0;
  }
}

.header-menu-item.-contact a {
  display: block;
  padding: 30px 70px 30px 50px;
  background: #331A14;
  color: #fff;
}

@media print, screen and (max-width: 1120px) {
  .header-menu-item.-contact a {
    padding: 30px 35px 30px 30px;
  }
}

@media print, screen and (max-width: 750px) {
  .header-menu-item.-contact a {
    text-align: center;
    padding: 20px 35px 20px 30px;
  }
}

.header-menu-item.header-menu-item-logo {
  display: none;
}

@media print, screen and (max-width: 992px) {
  .header-menu-item.header-menu-item-logo {
    display: block;
  }
  .header-menu-item.header-menu-item-logo img {
    width: 155px;
    height: auto;
  }
}

@media print, screen and (max-width: 992px) {
  #header-menu {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 20;
    color: #000;
    width: 100%;
    height: 100vh;
    border: 0;
    background: #F9F3E9;
    padding: 0;
  }
  #header-menu ul {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
  }
  #header-menu ul li {
    position: relative;
    margin-bottom: 40px;
    font-size: 15px;
    list-style-type: none;
    padding: 0;
    width: 100%;
  }
  #header-menu ul li:last-child {
    margin-top: 60px;
    padding-bottom: 0;
  }
  #header-menu ul li.header-menu-item-logo {
    margin-bottom: 45px;
  }
}

.navToggle {
  display: none;
}

.navToggle p {
  font-size: 10px;
}

@media print, screen and (max-width: 992px) {
  .navToggle {
    /* このクラスを、jQueryで付与・削除する */
    display: block;
    position: absolute;
    right: 10px;
    top: 5px;
    width: 30px;
    height: 18px;
    cursor: pointer;
    z-index: 2000;
    text-align: center;
    /* 最初のspanをマイナス45度に */
    /* 2番目と3番目のspanを45度に */
  }
  .navToggle span {
    display: block;
    position: absolute;
    /* .navToggleに対して */
    width: 30px;
    height: 2px;
    background: #331A14;
    transition: 0.35s ease-in-out;
  }
  .navToggle span:nth-child(1) {
    top: 16px;
  }
  .navToggle span:nth-child(2) {
    top: 25px;
  }
  .navToggle span:nth-child(3) {
    top: 33px;
  }
  .navToggle p {
    position: absolute;
    top: 35px;
  }
  .navToggle.active {
    background: none;
  }
  .navToggle.active span:nth-child(1) {
    border-color: #fff;
    top: 27px;
    transform: rotate(-30deg);
  }
  .navToggle.active span:nth-child(2),
  .navToggle.active span:nth-child(3) {
    border-color: #fff;
    top: 27px;
    transform: rotate(30deg);
  }
}

footer {
  padding: 40px 0;
  background: #331A14;
  text-align: center;
}

footer small {
  display: block;
  margin-top: 30px;
  font-size: 12px;
  color: #fff;
  line-height: 2.41667;
}

.section-title {
  margin-bottom: 80px;
  padding: 50px 0;
  text-align: center;
}

.section-title .ja {
  display: block;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.1em;
}

.section-title .en {
  display: block;
  margin-top: 18px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.section-title.no01 {
  background: url(../images/common/bg-title01.png) no-repeat center;
}

.section-title.no01 .en {
  color: #E07000;
}

.section-title.no02 {
  background: url(../images/common/bg-title02.png) no-repeat center;
}

.section-title.no02 .en {
  color: #E03C00;
}

@media print, screen and (max-width: 750px) {
  .section-title {
    margin-bottom: 30px;
  }
  .section-title .ja {
    font-size: 30px;
  }
  .section-title .en {
    font-size: 14px;
  }
}

.mv.-top {
  width: 100%;
  height: 1042px;
  background: url(../images/top/mv.jpg) no-repeat bottom;
  background-size: cover;
}

@media print, screen and (max-width: 750px) {
  .mv.-top {
    height: 147.46667vw;
    background: url(../images/top/sp/mv.jpg) no-repeat center;
    background-size: cover;
  }
}

.mv.-grand-menu {
  background: url(../images/page/mv-menu.jpg) no-repeat center top;
}

@media print, screen and (max-width: 750px) {
  .mv.-grand-menu {
    position: relative;
    background: url(../images/page/sp/mv-menu.jpg) no-repeat center top;
    background-size: contain;
    height: 53.33333vw;
  }
}

.mv-title {
  padding: 237px 0 315px;
  font-size: 70px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}

@media print, screen and (max-width: 750px) {
  .mv-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    padding: 0;
    width: 100%;
    font-size: 30px;
  }
}

.about-item {
  height: 685px;
  position: relative;
}

@media print, screen and (max-width: 992px) {
  .about-item {
    padding: 0 20px;
    height: 100%;
  }
  .about-item .container {
    padding: 0;
  }
}

.about-item.no01 {
  position: relative;
  background: url(../images/top/bg-about01.png) no-repeat right bottom;
  background-size: 34.5% auto;
}

.about-item.no02 {
  position: relative;
  background: url(../images/top/bg-about02.png) no-repeat left center;
  background-size: 34.5% auto;
}

@media print, screen and (max-width: 992px) {
  .about-item.no02 {
    margin-top: 30px;
  }
}

.about-photo {
  height: 550px;
  margin-bottom: 85px;
  padding-bottom: 40px;
}

@media print, screen and (max-width: 992px) {
  .about-photo {
    height: 53.33333vw;
  }
}

.no01 .about-photo {
  background: url(../images/top/about01.jpg) no-repeat left center;
  background-size: cover;
  width: 65.5%;
  position: absolute;
  left: 0;
}

.no02 .about-photo {
  background: url(../images/top/about02.jpg) no-repeat right center;
  background-size: cover;
  width: 65.5%;
  position: absolute;
  right: 0;
}

@media print, screen and (max-width: 992px) {
  .about-photo {
    margin-bottom: 0;
  }
  .no01 .about-photo,
  .no02 .about-photo {
    display: block;
    position: static;
    right: unset;
    left: unset;
    width: 100%;
  }
}

.about-text {
  position: relative;
  width: 58%;
  max-width: 600px;
  min-width: 375px;
  height: auto;
  border-top: 5px solid #E03C00;
  background: #fff;
  padding: 45px 50px;
  line-height: 1.83333;
  z-index: 10;
}

.no01 .about-text {
  top: 160px;
  margin-left: 50%;
}

.no02 .about-text {
  top: 140px;
}

@media print, screen and (max-width: 992px) {
  .about-text {
    width: 100%;
    max-width: unset;
    min-width: unset;
    padding: 15px;
  }
  .no01 .about-text {
    top: 0;
    margin-left: 0;
  }
  .no02 .about-text {
    top: 0;
  }
}

.about-text p + p {
  margin-top: 20px;
}

.card__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card__list.-takeout {
  margin-top: -90px;
}

.card__list.-grand-menu {
  margin-top: -30px;
}

@media print, screen and (max-width: 992px) {
  .card__list {
    flex-direction: column;
  }
  .card__list.-takeout {
    margin-top: 30px;
  }
}

.card__item {
  color: #fff;
}

.card__item.-takeout {
  margin-top: 90px;
  width: 560px;
  border: 6px solid #F4E8D4;
}

@media print, screen and (max-width: 1120px) {
  .card__item.-takeout {
    width: 480px;
  }
}

@media print, screen and (max-width: 992px) {
  .card__item.-takeout {
    margin-top: 30px;
    width: 100%;
  }
}

.card__item.-grand-menu {
  margin-top: 30px;
  width: 380px;
  border: 6px solid #F4E8D4;
}

@media print, screen and (max-width: 1160px) {
  .card__item.-grand-menu {
    width: 320px;
  }
}

@media print, screen and (max-width: 992px) {
  .card__item.-grand-menu {
    width: 100%;
  }
}

.card__item img {
  width: 100%;
  height: auto;
}

.bg-color03 {
  background: linear-gradient(to right, #503c91 0%, #331a14 100%);
}

.bg-takeout {
  background: url(../images/top/bg-takeout.png) repeat-y top center;
}

@media print, screen and (max-width: 750px) {
  .bg-takeout {
    background: url(../images/top/sp/bg-takeout.png) repeat-y top center;
    background-size: contain;
  }
}

@media screen and (min-width: 1920px) {
  .bg-takeout {
    background-size: contain;
  }
}

@media screen and (min-width: 769px) {
  .bg-grand-menu-head {
    background: url(../images/top/bg-grand-menu01.png) no-repeat left top;
  }
  .bg-grand-menu-bottom {
    background: url(../images/top/bg-grand-menu02.png) no-repeat right bottom;
  }
}

@media print, screen and (max-width: 992px) {
  .bg-grand-menu {
    background: url(../images/top/sp/bg-grand-menu.png) repeat-y;
    background-size: contain;
  }
}

.menu-card__content {
  padding: 30px 40px 40px 40px;
  text-align: center;
}

@media screen and (min-width: 993px) {
  .menu-card__content.bg-color01 {
    background: linear-gradient(to right, #e07000 0%, #331a14 100%);
  }
  .menu-card__content.bg-color02 {
    background: linear-gradient(to right, #e03c00 0%, #331a14 100%);
  }
}

@media print, screen and (max-width: 992px) {
  .card__item:nth-child(odd) .menu-card__content {
    background: linear-gradient(to right, #e07000 0%, #331a14 100%);
  }
  .card__item:nth-child(even) .menu-card__content {
    background: linear-gradient(to right, #e03c00 0%, #331a14 100%);
  }
}

@media print, screen and (max-width: 1120px) {
  .menu-card__content {
    padding: 15px 20px 20px 20px;
  }
}

.menu-card__name {
  border-bottom: 1px solid #fff;
  padding-bottom: 15px;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.1em;
}

.menu-card__price {
  padding-top: 15px;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.1em;
}

.menu-card__price .small {
  font-size: 20px;
}

.p-info-btn-wrapper {
  background-color: #fff;
  margin: 100px auto 0;
  position: relative;
  width: 450px;
  max-width: 100%;
  text-align: center;
  background-color: #fff;
}

@media print, screen and (max-width: 992px) {
  .p-info-btn-wrapper {
    width: 345px;
  }
}

.p-info-btn-wrapper::after {
  position: absolute;
  display: inline-block;
  content: "";
  top: 5px;
  left: 5px;
  z-index: 1;
  background: #E03C00;
  width: 100%;
  height: 80px;
}

@media print, screen and (max-width: 992px) {
  .p-info-btn-wrapper::after {
    left: unset;
    right: -5px;
    width: 345px;
    height: 56px;
  }
}

.c-info-btn {
  width: 450px;
  max-width: 100%;
  display: inline-block;
  padding: 25px 13px;
  background: #fff;
  border: 2px solid #331A14;
  text-align: center;
  font-weight: bold;
  font-size: 26px;
  color: #331A14;
  position: relative;
  z-index: 30;
}

.c-info-btn:hover {
  opacity: 1;
  background: #331A14;
  color: #fff;
}

@media print, screen and (max-width: 992px) {
  .c-info-btn {
    padding: 17px 13px;
    font-size: 18px;
  }
}

@media print, screen and (max-width: 750px) {
  .c-info-btn {
    width: 345px;
  }
}

.contact.no01 {
  margin-top: 95px;
  text-align: center;
}

.contact.no01 .contact__lead {
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.1em;
}

@media print, screen and (max-width: 992px) {
  .contact.no01 .contact__lead {
    font-size: 24px;
    line-height: 1.75;
  }
}

.contact.no01 .contact__number {
  display: inline-block;
  margin-top: 30px;
  padding-left: 60px;
  background: url(../images/common/tel.svg) no-repeat left center;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #331A14;
}

@media print, screen and (max-width: 750px) {
  .contact.no01 .contact__number {
    padding-left: 40px;
    background-size: 30px 27px;
    font-size: 37px;
  }
}

.contact.no02 {
  text-align: center;
}

.contact.no02 .contact__lead {
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1.5;
}

@media print, screen and (max-width: 992px) {
  .contact.no02 .contact__lead {
    font-size: 24px;
  }
}

.contact.no02 .contact__number {
  display: inline-block;
  margin-top: 55px;
  padding: 25px 70px 25px 130px;
  background: url(../images/common/tel.svg) no-repeat left 70px center;
  background-color: #fff;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #331A14;
}

@media print, screen and (max-width: 750px) {
  .contact.no02 .contact__number {
    padding: 25px 0 25px 60px;
    background: url(../images/common/tel.svg) no-repeat left 10px center;
    background-size: 30px 27px;
    background-color: #fff;
    font-size: 37px;
  }
}

.contact-box {
  width: 52%;
  min-width: 993px;
  margin: 80px auto 0;
  padding: 60px 50px 50px;
  background: #F9F3E9;
}

@media print, screen and (max-width: 992px) {
  .contact-box {
    width: 100%;
    min-width: auto;
    padding: 20px;
  }
}

.contact__title {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-align: center;
}

@media print, screen and (max-width: 992px) {
  .contact__title {
    font-size: 22px;
    line-height: 1.5;
  }
}

.contact__text {
  margin-top: 20px;
  line-height: 1.83333;
}

.section.bg-access {
  background: url(../images/top/bg-access01.png) no-repeat left top 164px, url(../images/top/bg-access02.png) no-repeat right bottom;
  padding: 150px 0 103px;
}

@media print, screen and (max-width: 750px) {
  .section.bg-access {
    background: url(../images/top/sp/bg-access01.png) no-repeat left top 280px, url(../images/top/sp/bg-access02.png) no-repeat right bottom;
    padding: 150px 0 15px;
  }
}

.access__list {
  margin-top: 75px;
  text-align: center;
}

@media print, screen and (max-width: 750px) {
  .access__list {
    margin-top: 40px;
  }
}

.access__item {
  font-size: 22px;
  text-align: center;
  line-height: 2;
}

.access__item + .access__item {
  margin-top: 20px;
}

@media print, screen and (max-width: 750px) {
  .access__item {
    font-size: 18px;
    line-height: 1.5;
  }
}

.gmap {
  height: 600px;
  overflow: hidden;
}

.gmap .gmap__inner {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
}

.gmap iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

@media print, screen and (max-width: 992px) {
  .gmap {
    height: auto;
  }
}

.bg-contact {
  background: url(../images/top/bg-contact01.png) no-repeat left top, url(../images/top/bg-contact02.png) no-repeat right bottom;
}

@media print, screen and (max-width: 750px) {
  .bg-contact {
    background: url(../images/top/sp/bg-contact01.png) no-repeat left top, url(../images/top/sp/bg-contact02.png) no-repeat right bottom;
  }
}

.link__list {
  display: flex;
}

@media print, screen and (max-width: 992px) {
  .link__list {
    flex-wrap: wrap;
  }
}

.link__item {
  position: relative;
  width: 25%;
}

.link__item a {
  display: block;
  width: 100%;
  height: 100%;
}

.link__item a:hover {
  opacity: 1;
}

.link__item a::after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  transition: 1.0s;
  background: transparent linear-gradient(135deg, var(--unnamed-color-e07000) 0%, var(--unnamed-color-331a14) 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(135deg, #E07000 0%, #331A14 100%) 0% 0% no-repeat padding-box;
  opacity: 0.4;
}

.link__item a:hover::after {
  background: transparent linear-gradient(135deg, var(--unnamed-color-e07000) 0%, var(--unnamed-color-331a14) 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(135deg, #E03C00 0%, #331A14 100%) 0% 0% no-repeat padding-box;
  opacity: 0.7;
}

.link__item .link__image {
  width: 100%;
  height: auto;
}

.link__item span {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  z-index: 100;
  text-align: center;
}

.link__item span img {
  display: inline-block;
}

.link__item span:hover {
  opacity: 1;
}

@media print, screen and (max-width: 1120px) {
  .link__item span {
    font-size: 30px;
  }
}

@media print, screen and (max-width: 992px) {
  .link__item span {
    font-size: 18px;
  }
  .link__item span img {
    margin-top: 15px;
  }
}

@media print, screen and (max-width: 992px) {
  .link__item {
    width: 50%;
  }
}

.bg-page-grand-menu {
  padding-bottom: 100px;
  background: url(../images/page/bg-page-grand-menu.png) repeat-y center;
}

@media screen and (min-width: 1920px) {
  .bg-page-grand-menu {
    background-size: contain;
  }
}

@media print, screen and (max-width: 750px) {
  .bg-page-grand-menu {
    padding-bottom: 60px;
    background: url(../images/page/sp/bg-page-grand-menu.png) repeat-y center;
    background-size: contain;
  }
}

.menu__list {
  max-width: 600px;
  margin: 0 auto;
}

.menu__list img {
  width: 100%;
  height: auto;
}

.menu__item + .menu__item {
  margin-top: 30px;
}

/*-------------------------------------------
ページトップ
-------------------------------------------*/
.pagetop {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: block;
  width: 99px;
  height: 99px;
  display: block;
  text-decoration: none;
  z-index: 10;
}

.pagetop img {
  width: 100%;
  height: auto;
}

@media print, screen and (max-width: 750px) {
  .pagetop {
    width: 60px;
    height: 60px;
  }
}

.pagetop:hover {
  opacity: 0.7;
}

/*-------------------------------------------
スマホ表示有無
-------------------------------------------*/
@media screen and (max-width: 768px) {
  .visible-desktop {
    display: none;
  }
}

@media print, screen and (min-width: 769px) {
  .visible-phone {
    display: none;
  }
}
