/*-------------------------------------------
ページトップ
-------------------------------------------*/
.pagetop {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: block;
  width: 99px;
  height: 99px;
  display: block;
  text-decoration: none;
  z-index: 10;

  img {
    width: 100%;
    height: auto;
  }

  @include mq(sp) {
    width: 60px;
    height: 60px;
  }
}

.pagetop:hover {
  opacity: 0.7;
}

/*-------------------------------------------
スマホ表示有無
-------------------------------------------*/
@media screen and (max-width: 768px) {
  .visible-desktop {
    display: none;
  }
}

@media print,
screen and (min-width: 769px) {
  .visible-phone {
    display: none;
  }
}