.container {
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 20px;

  @include mq(sp) {
    padding: 0 20px;
  }
}

.section {
  padding: 150px 0;

  @include mq(sp) {
    padding: 40px 0;
  }
}