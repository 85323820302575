.bg-page-grand-menu {
  padding-bottom: 100px;
  background: url(../images/page/bg-page-grand-menu.png)repeat-y center;

  @media screen and (min-width: 1920px) {
    background-size: contain;
  }

  @include mq(sp) {
    padding-bottom: 60px;
    background: url(../images/page/sp/bg-page-grand-menu.png)repeat-y center;
    background-size: contain;
  }
}


.menu__list {
  max-width: 600px;
  margin: 0 auto;

  img {
    width: 100%;
    height: auto;
  }
}

.menu__item {
  +.menu__item {
    margin-top: 30px;
  }
}