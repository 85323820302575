$pc: 751px;
$tb: 992px;
$sp: 750px;
$sp-s: 560px;

@mixin mq($b) {

  //PCの場合
  @if $b==pc {

    @media print,
    screen and (min-width: ($pc)) {
      @content;
    }
  }

  @else if $b==tb {

    @media print,
    screen and (max-width: ($tb)) {
      @content;
    }
  }

  @else if $b==sp {

    @media print,
    screen and (max-width: ($sp)) {
      @content;
    }
  }

  @else if $b==sp-s {

    @media print,
    screen and (max-width: ($sp-s)) {
      @content;
    }
  }
}

// 第一引数で基準となる画面幅のフォントサイズ、第二引数で基準となる画面幅を入力
@function get_vw($size, $viewport: 992) {
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size: 10) {
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}

$viewport: 375; // 基準となるビューポート

@function pxtovw($num, $width: $viewport) {
  @return (1vw * $num / $width * 100);
}

@mixin flex-start {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@mixin flex-end {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

@mixin flex-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@mixin flex-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@mixin flex-around {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

@mixin flex-all_center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}